.p {
  width: 500px;
  max-height: 40vw;
  height: 300px;
  margin: 20px 10px;
  border: 2px solid rgb(243, 242, 242);
  border-radius: 10px;
  overflow: hidden;
}

.p-browser {
  height: 20px;
  background-color: rgb(243, 242, 242);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  position: -webkit-sticky;
  z-index: 2;
}

.p-browser-left {
  display: flex;
}

.p-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 3px;
}

.p-circle:first-child {
  background-color: red;
}

.p-circle:nth-child(2) {
  background-color: orange;
}

.p-circle:last-child {
  background-color: rgb(33, 174, 33);
}

.p-img {
  width: 100%;
  transition: all 20s ease;
}

.p:hover .p-img {
  transform: translateY(-100%);
}

.p-github {
  width: 2.1875rem;
}
