*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Custom Properties */

:root {
  --ff-primary: "Source Sans Pro", sans-serif;
  --ff-secondary: "Source Sans Pro", monospace;

  --fw-reg: 300;
  --fw-bold: 900;

  --clr-light: #fff;
  --clr-dark: #303030;
  --clr-accent: #16e0bd;
  --clr-sunshine: linear-gradient(to bottom, rgb(255, 157, 0), black);

  /* #ffde0025 */

  --fs-h1: 3rem;
  --fs-h2: 2.25rem;
  --fs-h3: 1.25rem;
  --fs-body: 1rem;

  --bs: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.25),
    0.125em 0.125 0.25em rgba(0, 0, 0, 0.15);
}

@media (min-width: 800px) {
  :root {
    --fs-h1: 4.5rem;
    --fs-h2: 3.75rem;
    --fs-h3: 1.5rem;
    --fs-body: 1.125rem;
  }
}

/* General Styles */

body {
  background: var(--clr-sunshine);
  color: var(--clr-dark);
  margin: 0;
  font-family: var(--ff-primary);
  font-size: var(--fs-body);
  line-height: 1.6;
}

section {
  padding: 5em 2em;
}

img {
  display: block;
  max-width: 100%;
}

strong {
  font-weight: var(--fw-bold);
}

/* Typography */

h1,
h2,
h3 {
  line-height: 1.1;
  margin: 0;
}

h1 {
  font-size: var(--fs-h1);
}

h2 {
  font-size: var(--fs-h2);
}

h3 {
  font-size: var(--fs-h3);
}

a {
  /* background: var(--clr-accent); */
  text-decoration: none;
}

.section__title {
  margin-bottom: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section__title--intro {
  font-weight: var(--fw-reg);
  padding: 20px;
}

.section__title--intro strong {
  display: block;
}

.section__subtitle {
  margin: 0;
  font-size: var(--fs-h3);
}

.section__subtitle--intro {
  display: inline-block;
}

/* Intro Section */

.weather {
  padding: 10px;
}

/* sun start */

.sun {
  position: absolute;
  top: 100px;
  width: 200px;
  height: 200px;
  background-color: #ffde00;
  border-radius: 50%;
  box-shadow: 0 0 0 20px #ffde0080, 0 0 0 40px #ffde0040, 0 0 0 80px #ffde0010,
    0 0 0 100px #ffde0000, 0 0 40px 100px #ffde0010;
  animation: sunshine 2s infinite linear forwards, rays 2s 2s infinite linear,
    sunrise 3s ease;
  z-index: -1;
}

@keyframes sunshine {
  0% {
    box-shadow: none;
  }
}

@keyframes rays {
  0% {
    box-shadow: 0 0 0 0px #ffde0080, 0 0 0 20px #ffde0080, 0 0 0 40px #ffde0040,
      0 0 0 60px #ffde0020, 0 0 0 80px #ffde0010, 0 0 40px 80px #ffde0010;
  }

  100% {
    box-shadow: 0 0 0 20px #ffde0080, 0 0 0 40px #ffde0040, 0 0 0 80px #ffde0010,
      0 0 0 100px #ffde0000, 0 0 40px 100px #ffde0010;
  }
}

@keyframes sunrise {
  0% {
    top: 100%;
  }

  100% {
    top: 100px;
  }
}

/* sun end */

/* cloud start */

.cloud {
  margin-top: 70px;
  width: 350px;
  height: 120px;
  background: #eceff1;
  box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  z-index: 3;
}
.cloud::after,
.cloud::before {
  content: "";
  position: relative;
  display: inline-block;
  background: inherit;
  border-radius: inherit;
}
.cloud::after {
  width: 100px;
  height: 100px;
  top: -130px;
  left: -125px;
}
.cloud::before {
  width: 180px;
  height: 180px;
  top: -80px;
  left: 130px;
}

.about-me .cloud {
}

@media (max-width: 800px) {
  .about-me .cloud {
    display: none;
  }
}

@media (max-width: 450px) {
  .sun {
    display: flex;
    height: 100px;
    width: 100px;
  }
  .cloud {
    display: none;
  }
}

/* cloud end */

.intro {
  gap: 50px;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro h1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (max-width: 800px) {
  .intro {
    display: flex;
    flex-direction: column;
  }
}

/* portfolio section */

.portfolio {
  /* background-color: white; */
  color: black;
  min-height: 100vh;
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.portfolio__texts {
  width: 65%;
  max-width: 750px;
}

.portfolio__title {
  font-size: 25px;
  font-weight: 600;
}

.portfolio__description {
  margin: 20px 0px;
}

.portfolio__list {
  width: 70vw;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
@media (max-width: 600px) {
  .portfolio__texts {
    width: 100%;
  }
}
@media (max-width: 800px) {
  .portfolio__title span {
    display: none;
  }
}

/* about section */

.about-me {
  max-width: 1000px;
  margin: 0 auto;
  min-height: 100vh;
  color: white;
}

.about-me .about-me__body {
  padding: 20px;
}

/* .about-me a {
  background-color: #f1d100;
  color: white;
  border-radius: 10px;
  padding: 3px;
} */

.about-me a {
  border-radius: 3px;
  padding: 3px;
  border: 1px solid transparent;
}

.about-me .music-span a {
  background-color: #e1ecf4;
  color: hsl(205, 47%, 42%);
  border-color: hsl(205, 41%, 63%);
  box-shadow: inset 0 1px 0 0 hsl(0deg 0% 100% / 70%);
}

.about-me .stats-span a {
  background-color: #0b96fe;
  color: white;
  box-shadow: inset 0 1px 0 0 hsl(0deg 0% 100% / 40%);
}

.about-me .socials-span a {
  background-color: white;
  color: hsl(205, 47%, 42%);
  border-color: hsl(205, 41%, 63%);
  box-shadow: inset 0 1px 0 0 hsl(0deg 0% 100% / 70%);
}

.about-me .yt-span a {
  background-color: white;
  color: red;
  border-color: red;
  box-shadow: inset 0 1px 0 0 hsl(0deg 0% 100% / 70%);
}

.about-me .socials-span a:first-child {
  margin-right: 5px;
}

.about-me__img {
  box-shadow: var(--bs);
}

@media (min-width: 600px) {
  .about-me {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .section__title-about {
    grid-area: title;
  }

  .about-me__img {
    grid-area: img;
    position: relative;
    z-index: 2;
  }
}
